<template>
  <div id="item-id">
    <div class="card just-box-rounded">
      <div class="bar-contained-card">
        <div class="" style="width: 100%">
          <div class="card-container-thin">
            <div class="media">
              <div style="width: 100%">
                <div class="columns">
                  <div class="column is-8">
                    <div class="media-content card-new-header">
                      <p v-if="showFullName" class="title is-6" @mouseleave="showFullName = false">
                        {{ itemDisplayName }}
                      </p>
                      <p v-else class="title is-6" @mouseover="showFullName = true" @mouseleave="showFullName = false">
                        {{ itemDisplayName | truncate(16, "...") }}
                      </p>                      
                    </div>
                  </div>
                  <div class="column" :class="itemInvalidated || sharedBy !== '' ? '' : 'inherit-margin'">
                    <div class="on-end">
                      <div>
                        <!-- <div v-if="itemData.report_only">
                          <span class="report-tag">Report</span>
                        </div> -->
                        <!-- {{ itemData.activity_status }}{{ itemData.report_only }} -->

                        <div>
                          <span v-if="itemInvalidated" class="tag-custom tag-border-defult tag-text-red"
                            style="margin: auto;">Revoked</span>
                          <span v-else-if="sharedBy !== ''" class="tag-custom"
                            style="margin: auto;">
                            <span class="icon" @mouseover="showSharedBy = true" @mouseleave="showSharedBy = false">
                              <img v-show="!showSharedBy" src="../assets/icon_svg/sharedby.svg" />
                            </span>
                            <span v-if="showSharedBy" class=""><strong>Shared By: {{ sharedBy }}</strong></span>
                          </span>
                        </div>
                        <!-- <div v-else class="on-end" style="padding-bottom: 10px; gap: 7px;">
                              <span :class="setDotColor(itemData)"></span>
                              <span :class="setBarColor(itemData)"></span>
                            </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="content has-text-left">
              <div class="card-content center" style="width: max-content;">
                <div>
                  <div class="pending-on-container">
                    <span class="pending-on-container is-purple has-text-white" style="vertical-align: sub;">
                      Token ID :
                    </span>
                    <b-tooltip :label="itemID">
                      <span class="name-pending-value" style="vertical-align: sub;">
                        {{ itemID | truncate(8, "...") }}
                      </span>
                    </b-tooltip>
                    <span>
                      <button class="button button-new is-small is-light"
                        style="margin-left: 12px; background-color: white; padding-top: 1px; padding-bottom: 6px;"
                        @click="copyToClipboard(itemID, 'id')" :disabled="deleting">
                        <span class="icon is-small" style="margin-top: 8px; padding: 1px;">
                          <img v-if="isIDCopied" src="../assets/icon_svg/tick.svg" />
                          <img v-else src="../assets/icon_svg/copy.svg" />
                        </span>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-button-container">
                <div id="navbarBasicExample" class="navbar-menu">
                  <div class="navbar-start">
                    <div class="navbar-item navbar-item-padding">
                      <button class="button is-small is-light" :class="isCopied ? 'button-copied' : 'button-new'"
                        @click="showWID = false; copyToClipboard(itemWID, 'wid')" :disabled="deleting"
                        @mouseover="showWID = true" @mouseleave="showWID = false; isCopied = false;">
                        <span class="icon is-small">
                          <img src="../assets/icon_svg/info.svg" />
                        </span>
                        <span v-if="showWID && !isCopied" class="">{{ itemWID }}</span>
                        <span v-if="isCopied" class="">Copied</span>
                      </button>
                    </div>
                    <div class="navbar-item navbar-item-padding">
                      <button class="button button-new is-grey is-small is-light" @mouseover="showLabel = true"
                        @mouseleave="showLabel = false" @click="$root.$emit('select_token', itemID)"
                        :disabled="itemInvalidated || deleting">
                        <span class="icon is-small">
                          <img src="../assets/icon_svg/share.svg" />
                        </span>
                        <span v-if="showLabel" class="">Share</span>
                      </button>
                    </div>
                    <div class="navbar-item navbar-item-padding">
                      <button class="button button-new is-small is-light" @mouseover="showLabelSelect = true"
                        @mouseleave="showLabelSelect = false" @click="triggerDownload(itemID)"
                        :disabled="itemInvalidated || deleting">
                        <span class="icon is-small">
                          <img src="../assets/icon_svg/download.svg" />
                        </span>
                        <span v-if="showLabelSelect" class="">Artifacts</span>
                      </button>
                    </div>
                    <div class="navbar-item navbar-item-padding">
                      <button class="button button-new is-small is-light" @mouseover="showUsersLabel = true"
                        @mouseleave="showUsersLabel = false" :disabled="itemInvalidated || deleting"
                        @click="$root.$emit('token_recipients', itemID)">
                        <span class="icon is-small">
                          <img src="../assets/icon_svg/users.svg" />
                        </span>
                        <span v-if="showUsersLabel" class="">Recipients</span>
                      </button>
                    </div>
                    <div class="navbar-item navbar-item-padding">
                      <button class="button button-new is-small is-light" @mouseover="showCertLabel = true"
                        @mouseleave="showCertLabel = false" :disabled="itemInvalidated || deleting"
                        @click="$root.$emit('token_certificate', itemID)">
                        <span class="icon is-small">
                          <img src="../assets/icon_svg/cert.svg" />
                        </span>
                        <span v-if="showCertLabel" class="">Certificate</span>
                      </button>
                    </div>
                    <div v-if="isOwner && !showWID" class="navbar-item navbar-item-padding">
                      <button class="button button-delete is-small is-light" @click="triggerDelete(itemID)"
                        :disabled="deleting" @mouseover="showDeleteLabel = true" @mouseleave="showDeleteLabel = false">
                        <span class="icon is-small">
                          <img src="../assets/icon_svg/delete.svg" />
                        </span>
                        <span v-if="showDeleteLabel" class="">Delete</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OverlayPanel from "primevue/overlaypanel";
import { globalFunction } from "../services/global.function";

import moment from "moment";
export default {
  props: {
    itemState: String,
    itemDisplayName: String,
    itemID: String,
    itemWID: String,
    cardid: Number,
    itemInvalidated: Boolean,
    isOwner: Boolean,
    sharedBy: String,
  },
  data() {
    return {
      delay: 700,
      clicks: 0,
      showLabel: false,
      showLabelSelect: false,
      showFullName: false,
      showDeleteLabel: false,
      showUsersLabel: false,
      showCertLabel: false,
      showWID: false,
      showSharedBy: false,
      timer: null,
      txns: [],
      subFloderItem: [],
      currentFolder: "",
      isCopied: false,
      isIDCopied: false,
      deleting: false,
    };
  },
  components: {
    OverlayPanel,
  },
  computed: {
    actualWorkFlowID() {
      return this.$store.getters.getWorkFlowID;
    },
    operationIsLoading: {
      get: function () {
        return this.$store.getters.isLoading;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("isLoading", newValue);
      },
    },
  },
  methods: {
    localTime(utcTime) {
      if (utcTime != "NA") {
        return moment
          .utc(utcTime, "DD MMM YYYY HH:mm:ss")
          .local()
          .calendar({ sameElse: "DD MMM YYYY HH:mm:ss" });
      } else {
        return "NA";
      }
    },
    triggerDelete(id) {
      console.log("delete id:", id, this);
      this.deleting = true;
      this.$root.$emit("burn_token", id);
    },
    triggerDownload(id) {
      console.log("download id:", id);
      this.$root.$emit("download_token", id);
    },
    copyToClipboard(data, type) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(data).then(() => {
          if (type == 'wid') {
            console.log("wid copied:", data);
            this.isCopied = true;
            setTimeout(() => {
              this.isCopied = false;
            }, 2000);
          } else if (type == 'id') {
            console.log("id copied:", data);
            this.isIDCopied = true;
            setTimeout(() => {
              this.isIDCopied = false;
            }, 2000);
          }
        });
      }
    },
  },
};
</script>
